@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "AppleSDGothicNeoRegular";
  src: url("../public/font/AppleSDGothicNeoRegular.eot");
  src: url("../public/font/AppleSDGothicNeoRegular.eot") format("embedded-opentype"),
    url("../public/font/AppleSDGothicNeoRegular.woff") format("woff"), url("../public/font/AppleSDGothicNeoRegular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

/* @media (hover: hover) and (pointer: fine) {
  #root {
    width: 1278px;
    min-width: 1278px;
    min-height: 100vh;
    margin: 0 auto;
  }
}

@media (hover: none) and (pointer: coarse) {
  #root {
    width: 100%;
    height: 100vh;
  }
} */

/* hover여부가 아닌 스크린사이즈로 pc, 모바일 판단수정 */

/* width가 764px 이상일 때 적용 (데스크탑) */
@media (min-width: 764px) {
  #root {
    width: 1278px;
    min-width: 1278px;
    height: 100%;
    margin: 0 auto;
  }
}

/* width가 764px 이하일 때 적용 (모바일) */
@media (max-width: 764px) {
}

html {
  font-size: 10px;
  line-height: 10px;
  height: 100%;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
